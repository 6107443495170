// 8.7% of browsers doesn't support replaceAll as of 2021-11-10
import 'core-js/es/string/replace-all'

import '../styles/globals.css'
import '../styles/styles.scss'
import {AppProps} from 'next/app'
import React from 'react'
import Head from 'next/head'

function MyApp({Component, pageProps, err}: AppProps & {err: any}) {
  return (
    <>
      <Head>
        <title>Reflect</title>
      </Head>
      <Component {...pageProps} err={err} />
    </>
  )
}

export default MyApp
